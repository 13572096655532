body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: white;
  color: black;
}

header {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 1em 0;
}

main {
  padding: 20px;
}

footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 1em 0;
  position: absolute;
  bottom: 0;
  width: 100%;
}

nav {
  display: center;
  justify-content: space-between;
  align-items: center;
}

button {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
}

/* Dark Mode Stil */
.App.dark-mode {
  background-color: #222;
  color: white;
}

nav table {
  width: 100%;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: inline-block; /* This centers the list inside the TD */
}

nav ul li {
  display: inline;
  margin-right: 10px;
}

nav ul li a {
  color: white;
  text-decoration: none;
}

#darkModeToggle {
  background-color: #ffffff; /* You can adjust this */
  color: rgb(0, 0, 0); /* You can adjust this */
  border: none; /* This removes the default border around buttons */
  padding: 5px 15px; /* Some padding to make the button look nicer */
  cursor: pointer; /* The hand cursor that indicates a clickable button */
}

#darkModeToggle:hover {
  background-color: #525252; /* A bit darker when hovering */
}